import * as React from "react";

import {
  Image,
  Link,
  Text,
  useMediaQuery,
  Box,
  HStack,
  VStack,
  Heading,
} from "@chakra-ui/react";

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { FunctionComponent } from "react";

type BlogHeroPostIntro = {
  link: string;
  blogImgThumb?: string;
  category?: string;
  postTitle: string;
  excerpt: string;
  author?: string;
  date?: string;
  uri?: string;
  arrowImage?: string;
  heroBackgroundPadding?: string;
  modified?: string;
};
const BlogHeroPost: FunctionComponent<BlogHeroPostIntro> = ({
  link,
  blogImgThumb,
  category,
  postTitle,
  excerpt,
  author,
  date,
  modified,
}) => {
  const path = typeof window !== "undefined" ? window.location.pathname : "";

  return (
    <Box position="relative" h="100%" pt="80px" pb="16px">
      <Image
        alt={blogImgThumb}
        src={blogImgThumb}
        position="absolute"
        objectFit="cover"
        top="16px"
        right="0px"
        w="100%"
        height="inherit"
        zIndex="1"
      />
      <Box
        id="overlay"
        position="absolute"
        background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 43.14%, rgba(0, 0, 0, 0.80) 100%), rgba(18, 11, 12, 0.50);"
        top="16px"
        w="100%"
        height="inherit"
        zIndex="2"></Box>
      <VStack
        id="heroContent"
        position="relative"
        maxW={{ base: "100%", lg: "672px" }}
        h="100%"
        alignItems="center"
        justifyContent="center"
        margin="0px auto"
        px={{ base: "20px", xl: "0px" }}
        gap="20px"
        color="white"
        textAlign="center"
        zIndex="3">
        <Heading
          as="h1"
          dangerouslySetInnerHTML={{ __html: postTitle }}
          fontSize="36px"
          fontWeight="400"></Heading>
        <HStack>
          <Text fontWeight="700" pr="14px">
            {category}
          </Text>
          <Text borderLeft="1px solid white" fontWeight="700" pl="20px">
            {date}
          </Text>
        </HStack>
        <Text dangerouslySetInnerHTML={{ __html: excerpt }}></Text>
        <VStack>
          <Text fontWeight="700">{author}</Text>
        </VStack>
        <AnchorLink to={path + "#anchorlink"}>
          <img alt="" src="/remixiconslinesystemarrowdownsline1.svg" />
        </AnchorLink>
      </VStack>
    </Box>
  );
};

export default BlogHeroPost;
